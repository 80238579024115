<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('Perfil')}}
        </h3>
      </v-card-title>

      <v-card-text>

        <v-row v-if="loading">
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col cols="12">
            <div class="text--primary">
              <label>{{$i18n.t('Login')}}</label>
              <div>{{perfil.login}}</div>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col cols="12">
            <div class="text--primary">
              <label>{{$i18n.t('Email')}}</label>
              <div>{{perfil.email}}</div>
            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  
  export default {
    data: () => ({
      loading: false,
      perfil: {}
    }),
    methods: {
      load() {
        this.loading = true;
        this.$http.get('/usuarios/me')
            .then((req) => {
                this.perfil = req.data;
            })
            .catch((e) => {
              this.$msg.erro(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
      },
      salva() {
        this.$router.push('/documento/add');
      }
    },
    mounted() {
      this.load();
    }
  }
</script>