<template>  
  <div>
    <v-row>
      <v-col cols="12" sm="12" lg="6">
        <dados></dados>
      </v-col>
      <v-col cols="12" sm="12" lg="6">
        <senha></senha>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dados from './Dados.vue'
import Senha from './Senha.vue'
  export default {
  components: { Dados, Senha },
  }
</script>