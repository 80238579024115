<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('Senha')}}
        </h3>
      </v-card-title>

      <v-card-text>

        <v-row v-if="loading">
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-model="valido"
          lazy-validation>
          
          <v-text-field
            ref="senha"
            v-model="senha"
            type="password"
            :rules="senhaRules"
            :label="$i18n.t('SenhaAtual')"
            required>
          </v-text-field>

          <v-text-field
            ref="novaSenha"
            v-model="novaSenha"
            :rules="senhaRules"
            type="password"
            :label="$i18n.t('TextoNovaSenha')"
            required>
          </v-text-field>

          <v-text-field
            v-model="novaSenha2"
            :rules="senhaRules"
            type="password"
            :label="$i18n.t('TextoNovaSenha2')"
            required>
          </v-text-field>

        </v-form>

        <v-row v-if="!loading">
          <v-col cols="12" align="center">
            <v-btn
              @click="salva"
              color="success"
              :disabled="!validateForm"
              v-if="!loading">
              <v-icon>
                mdi-check
              </v-icon>
              {{$i18n.t('Salvar')}}
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  
  export default {
    data: () => ({
      loading: false,
      valido: false,
      senha: '',
      novaSenha: '',
      novaSenha2: '',
      senhaRules: [
        v => !!v
      ],
    }),
    computed: {
        validateForm() {
          return this.valido
            && this.senhaAtual != ''
            && this.novaSenha != ''
            && this.novaSenha2 != '';
        },
    },
    methods: {
      salva() {
        if (this.validateForm) {
          this.loading = true;
          if (this.novaSenha == this.novaSenha2) {
              this.$http.post('/usuarios/senha',
                {
                  "senha": this.senha,
                  "novaSenha": this.novaSenha
                })
                .then((res) => {
                  if (res.data.sucesso) {
                    this.$msg.sucesso(this.$i18n.t('Sucesso'));
                  }
                  else
                    this.$msg.erro(res.data.mensagem);
                })
                .catch((e) => {
                  this.$msg.erro(e.data);    
                })
                .finally(()=> {
                    this.loading = false;
                    this.$refs.senha.focus();
                });
          } else {
              this.loading = false;
              this.$msg.erro(this.$i18n.t('SenhasNovasSaoDiferentes'));
              this.$refs.novaSenha.focus();
          }
          
          this.$http.get('/usuarios/me')
              .then((req) => {
                  this.perfil = req.data;
              })
              .catch((e) => {
                this.$msg.erro(e.response.data.message);
              })
              .finally(() => {
                this.loading = false;
              });
        }
      },
      clear() {
        this.senha = '';
        this.novaSenha = '';
        this.novaSenha2 = '';
      }
    },
    mounted() {
      this.$refs.senha.focus();
    }
  }
</script>